<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.bulletins") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :ascending="false"
        :fields="fields"
        :items="bulletins"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="createdAt"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/bulletins/${id}`)"
      >
        <template #cell(createdAt)="{ value }">
          <span>{{ getLocaleDate(locale, value) }}</span>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomTable },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const bulletins = computed(() => store.state.bulletin.bulletins);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "topic",
        label: "topic",
      },
      {
        key: "createdAt",
        label: "date",
      },
    ]);
    const searchFields = ref(["topic", "createdAt"]);
    const sortFields = ref(["topic", "createdAt"]);

    onMounted(() => {
      store.dispatch("bulletin/getBulletins", user.value.currentEstate);
    });

    return {
      bulletins,
      fields,
      getLocaleDate,
      locale,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
